// src/components/GenerateWhatsAppLinkContent.js

import React from 'react';
import './GenerateWhatsAppLink.css'; // Assuming you have a CSS file for styling
import ScrollToTopButton from "../components/common/ScrollToTopButton";
import WhatsAppWidget from "../components/common/WhatsAppWidget";
import WhatsAppLinkGenerator from '../components/common/WhatsAppLinkGenerator';
import LogoCarousel from "../components/common/LogoCarousel";
import { getImage } from '../utils/getImage'; // Adjust the path to your utility function
import FAQs from "../components/common/FAQs"; // Correct relative path
import YoutubeVideoSlider from "../components/common/YoutubeVideoSlider";
import QRCodeGenerator from "../components/common/QRCodeGenerator";
const checkImage = getImage('icons/Vector.png');

// Fetching images dynamically using the getImage function
const whatsappImage = getImage('whatsapp-link-generator.webp'); // Import the image
const step1Image = getImage('List.png'); // Import Step 1 image
const step2Image = getImage('Ux.png'); // Import Step 2 image
const step3Image = getImage('QR code.png'); // Import Step 3 image
const step1 = getImage('marketing.png'); // Import Step 1 image
const step2 = getImage('bussiness.png'); // Import Step 2 image
const step3 = getImage('provider.png'); // Import Step 3 image

const GenerateWhatsAppLink = () => {
  // Function to handle button click and scroll to the form
  const scrollToForm = () => {
    document.getElementById('myForm').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <section style={{ background: "linear-gradient(85.35deg, #093055 0.77%, #12DFDF 98.65%)" }}>
        <div className="container py-5 mt-4 text-white">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 my-auto text-md-start">
              <span className='link-badge'>Free WhatsApp Link Generator</span>
              <h1 className="heading pt-3 text-white h2">Click. Create. Connect: Your QR Code Journey Starts Here</h1>
              <p className="py-3">Power up your sharing game with instant, customisable QR codes and capture leads seamlessly with Anantya.ai
              </p>
              <button
                type="button"
                onClick={scrollToForm}
                className="btn me-md-2 text-white linear_gradiant"
              > Build my QR Code
              </button>

            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <img
                src={whatsappImage} // Use the imported image
                className="img-fluid"
                alt="Generate WhatsApp URL"
              />
            </div>
          </div>
        </div>
      </section>
      <div className="text-center pt-5">
        <h2 className=" bg-gradient_heading">Trusted by 100+ brand across Global</h2>
        <LogoCarousel />
        <p
          className=" color-666 line_breaker bg-gradients text-center mt-5"
          style={{ visibility: "visible", animationName: "fadeInDown" }}
        >
          Share links, contact info, websites, payments, and more{" "}
          <a
            href="https://wa.link/hd4mvx"
            className="color-000 fw-bold text-white text-underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            <u> Get Started Now </u>
          </a>
        </p>
      </div>
      
      {/* Features Section */}
      <div className="container text-md-start py-5">
        <h2 className="text-center h4 whatsapp_heading">Generate Your WhatsApp Link in Just 3 Easy Steps</h2>
        <p className="text-center pb-2">Enhance User Experience and Drive Conversion</p>
        <div className="row row-cols-1 row-cols-md-3 g-4">
          {/* Step 1 */}
          <div className="col">
            <div className="widget-card h-100 py-md-4">
              <img src={step1Image} className="img-fluid stepimage my-3" alt="Step 1" />
              <h5>Personalize Your Details</h5>
              <p>Enter your display name and a welcoming message to create a strong first impression.</p>
            </div>
          </div>

          {/* Step 2 */}
          <div className="col">
            <div className="widget-card h-100 py-md-4">
              <img src={step2Image} className="img-fluid stepimage my-3" alt="Step 2" />
              <h5>Design Your QR Code</h5>
              <p>Pick from a range of designs, colors, and formats to perfectly reflect your brand.</p>
            </div>
          </div>

          {/* Step 3 */}
          <div className="col">
            <div className="widget-card h-100 py-md-4">
              <img src={step3Image} className="img-fluid stepimage my-3" alt="Step 3" />
              <h5>Download & Share</h5>
              <p>Download your custom QR code or generate a shareable link—whichever works best for you! Start connecting instantly.</p>
            </div>
          </div>
        </div>
      </div>
      <div className='d-none'>
      <WhatsAppLinkGenerator />
      </div>
      <div className="container">
      <span className='link-badge-2'>Your Gateway to Instant Connections: Create Your QR Code Now</span>

      <h2>Create a free QR Code and Bridge the Gap in Seconds</h2>
      <p>Generate your unique QR Code and unlock endless possibilities with a quick, reliable, <br/> and ready-to-share code</p>
      </div>
     <QRCodeGenerator />
      <section>
        <div className="container text-md-start py-5">
          <h2 className="text-center h4 whatsapp_heading pb-4">Transform Your Interactions: Explore the Hidden Benefits of <br /> WhatsApp Chat Links</h2>

          <div className="row row-cols-1 row-cols-md-3 g-4">
            {/* Step 1 */}
            <div className="col">
              <div className="link-card h-100 p-4">
                <img src={step1} className="img-fluid  my-3" alt="Step 1" />
                <h5>For Marketers</h5>
                <p>Drive traffic, improve campaign conversion rates, and foster direct communication with customers, all through a simple chat link</p>
              </div>
            </div>

            {/* Step 2 */}
            <div className="col">
              <div className="link-card h-100 p-4">
                <img src={step2} className="img-fluid  my-3" alt="Step 2" />
                <h5>For Businesses</h5>
                <p>Streamline customer support, enhance sales interactions, and boost customer engagement with easy access to WhatsApp chat.</p>
              </div>
            </div>

            {/* Step 3 */}
            <div className="col">
              <div className="link-card h-100 p-4">
                <img src={step3} className="img-fluid  my-3" alt="Step 3" />
                <h5>For Service Provider</h5>
                <p>Enable quick consultation bookings, resolve customer concerns, and offer personalised assistance right through WhatsApp.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='p-md-5 p-3' style={{ background: "linear-gradient(360deg, #0A9F8D 0%, #144D67 100.9%)" }}>

        <div className="container-fluid  text-white  bg-white py-2 mt-0">
          <h2 className='text-white heading-gradient'>Grow Your Business With WhatsApp Business API</h2>

          <form
            action="https://formspree.io/f/mdorezev"
            method="POST"
            id="myStickyForm"
            className="form-containers"
          >
            <h5 className="text-center my-2 text-black">Contact Details</h5>
            <div className="form-row">
              <input type="text" name="firstName" className="form-control" placeholder="First Name" required />
              <input type="text" name="lastName" className="form-control" placeholder="Last Name" required />
            </div>
            <div className="form-row">
              <div className="phone-container">
                <select name="countryCode" className="country-code">
                  <option value="+91">+91</option>

                </select>
                <input
                  type="text"
                  name="phone"
                  className="form-control ms-md-1"
                  placeholder="WhatsApp Number" required
                />
              </div>
              <input
                type="email"
                name="email"
                className="form-control ms-md-3"
                placeholder="Business Email" required
              />
            </div>

            <h5 className="text-center mb-2 text-black">Company Details</h5>
            <div className="form-row">
              <input type="text" name="companyName" className="form-control" required placeholder="Company Name" />
              <input type="text" name="website" className="form-control" placeholder="Website" />
            </div>
            <div className="form-row">
              <input type="text" name="industry" className="form-control" placeholder="Industry" required />
              <input
                type="text"
                name="socialMedia"
                className="form-control"
                placeholder="Social Media Link" required
              />
            </div>

            <div className="form-check  align-items-center form-row">
              <input
                className="form-check-input me-2 d-md-block d-none"
                type="checkbox"
                id="updates"
                name="updates"
              />
              <label
                className="form-check-label mt-2 d-md-block d-none  "
                htmlFor="updates"
                style={{ fontSize: "10px", textAlign: "left", color: "#000" }}
                required>
                Get Updates regarding your anantya.ai account on WhatsApp
              </label>
              <div className="recaptcha-container text-start mt-3">
                <div className="recaptcha-box">

                  <input
                    className="form-check-input me-3"
                    type="checkbox"
                    id="terms"
                    name="terms"
                    required
                  />
                  <label
                    className="form-check-label mt-2"
                    htmlFor="terms"
                    style={{ fontSize: "12px", textAlign: "left" }}
                  >
                    I'm not a robot
                  </label>
                  {/* Add Recaptcha Integration here */}
                </div>
              </div>
            </div>
            <div className="form-submit pb-4">
              <input type="submit" className="btn-submit" value="Book a Demo" />
            </div>
          </form>

        </div>
      </section>
      <section >
        <YoutubeVideoSlider />
      </section>

      {/* Call-to-Action Section */}
      <div className="container my-3">
        <div className="update-cta p-4">
          <div className="in-cta p-2">
            <h5 className="h6 pt-3 mt-2 px-md-5 mx-md-5">
              Elevate Your Communication Today! Discover how <b> Seamless Communication</b> Can Transform Your Business! Unlock the power of custom WhatsApp chat links and QR codes to enhance <b> engagement </b> and drive results. It’s time to take your communication to the next level.
            </h5>
            <div className="py-2 mb-2">
              <a href="https://wa.link/hd4mvx" className="btn btn-graident me-3 " role="button">
                Free 7 Day Trial
              </a>
              <a href="https://wa.link/hd4mvx" className="btn btn-graident-2 custom-border " role="button">
                Book a Demo
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Why Choose Anantya Section */}
      <FAQs />
      <div className="container my-3 py-0">
        <div className="py-5 px-0">
          <h5 className="px-5 mx-5 heading-gradient">Why Choose Anantya.ai?</h5>
          <div className="features d-flex py-3 justify-content-center text-center p-0">
            <div className="feature-item">
              <img src={checkImage} alt="Check" className="feature-tick-icon mx-2" />
              <span>Dedicated Customer Support</span>
            </div>
            <div className="feature-item mx-1">
              <img src={checkImage} alt="Check" className="feature-tick-icon mx-2" />
              <span>Tailored Solution for You</span>
            </div>
            <div className="feature-item mx-1">
              <img src={checkImage} alt="Check" className="feature-tick-icon mx-2" />
              <span>Seamless Setup in minutes</span>
            </div>
            <div className="feature-item">
              <img src={checkImage} alt="Check" className="feature-tick-icon mx-2" />
              <span>Proven Impact in Boosting engagement
              </span>
            </div>
          </div>
          <div className="py-2">
            <a href="https://wa.link/hd4mvx" className="btn btn-graident me-3" role="button">
              Try Anantya.ai
            </a>
          </div>
        </div>
      </div>
      <ScrollToTopButton />
      <WhatsAppWidget />
    </>
  );
};

export default GenerateWhatsAppLink;
