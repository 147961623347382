import React, { useState } from "react";
import './FAQSection.css'; // Ensure you have the CSS file in the same directory

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    { question: "How do I integrate the WhatsApp chat widget into my website?", answer: "Simply copy the widget code from your Anantya account and paste it into your website’s HTML. It's easy to set up and requires no technical expertise." },
    { question: "Can I customize the design of the WhatsApp widget?", answer: "Yes! You can personalize the chat button’s color, design, and call-to-action to match your website’s look and feel." },
    { question: "Is the WhatsApp chat widget free to use?", answer: "Yes, Anantya offers a free WhatsApp chat widget that can be added to your website to instantly connect with your visitors." },
    { question: "Will the widget work on all devices?", answer: "Absolutely! The WhatsApp chat widget is fully responsive and works seamlessly across all devices, including desktops, tablets, and smartphones." },
    { question: "How can I track the performance of the WhatsApp chat widget?", answer: "Anantya provides analytics and insights on interactions through the widget, so you can monitor engagement and optimize your customer communication." },
  ];

  return (
    <div className="faq-unique-section container">
      <h2 className="text-center mb-4  heading-gradient">Frequently Asked Questions</h2>
      <div className="row">
        {/* Column 1 */}
        <div className="col-md-6">
          {faqs.slice(0, 2).map((faq, index) => (
            <div
              key={index}
              className={`faq-item ${activeIndex === index ? "active" : ""}`}
            >
              <div
                className="faq-question d-flex justify-content-between align-items-center"
                onClick={() => toggleFAQ(index)}
              >
                <p>{faq.question}</p>
              
                <span className="faq-toggle-btn">{activeIndex === index ? "-" : "+"}</span>
              </div>
              {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
            </div>
          ))}
        </div>
        {/* Column 2 */}
        <div className="col-md-6">
          {faqs.slice(2).map((faq, index) => (
            <div
              key={index + 2}
              className={`faq-item ${activeIndex === index + 2 ? "active" : ""}`}
            >
              <div
                className="faq-question d-flex justify-content-between align-items-center"
                onClick={() => toggleFAQ(index + 2)}
              >
                <p>{faq.question}</p>
            
                <span className="faq-toggle-btn">{activeIndex === index + 2 ? "-" : "+"}</span>
              </div>
              {activeIndex === index + 2 && <p className="faq-answer">{faq.answer}</p>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;

