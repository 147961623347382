import React, { useState } from "react";
import './WhatsAppChatButton.css';
import { getImage } from '../../utils/getImage';

// Load images
const whatsappBackgroundImg = getImage('anantya-whatsapp.png');
const seekImage = getImage('sleekflow-icon.png.png');

const WhatsAppWidget = () => {
  const [formData, setFormData] = useState({
    buttonName: "Message Us",
    buttonSize: "Large",
    buttonPosition: "Right",
    brandName: "Anantya.ai",
    brandSubtitle: "Typically replies within a day",
    phoneNumber: "+91",
    brandImageUrl: "",
    welcomeMessage: "Anantya.ai <br> Hi there 👋",
    preFilledMessage: "Hi, I want to know more!",
    callToAction: "Start Chat",
    businessEmail: "",
    agreeToMarketing: false,
  });

  const [generatedScript, setGeneratedScript] = useState("");
  const [showScript, setShowScript] = useState(false);

  // Handle input change for form fields
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const script = `
      <!-- WhatsApp Button -->
      <script>
        (function() {
          const button = document.createElement('div');
          button.id = 'whatsapp-button';
          button.style.position = 'fixed';
          button.style.bottom = '20px';
          button.style.${formData.buttonPosition.toLowerCase()}: '20px';
          button.innerHTML = '<a href="https://wa.me/${formData.phoneNumber.replace(
            /\D/g,
            ""
          )}?text=${encodeURIComponent(
      formData.preFilledMessage
    )}" target="_blank" style="text-decoration:none;"><button style="font-size:${
      formData.buttonSize === "Small"
        ? "12px"
        : formData.buttonSize === "Medium"
        ? "16px"
        : "20px"
    }; padding:10px; background-color:green; color:white; border:none; border-radius:5px;">${
      formData.buttonName
    }</button></a>';
          document.body.appendChild(button);
        })();
      </script>
    `;
    setGeneratedScript(script.trim());
    setShowScript(true);
  };

  // Copy to clipboard functionality
  const copyToClipboard = () => {
    navigator.clipboard.writeText(generatedScript);
    alert("Script copied to clipboard!");
  };

  return (
    <section className="container pb-4">
      <div className="text-center pb-4">
        <span className="badge-btn"> WhatsApp Widget: Generate your own WhatsApp Button </span>
        <h2 className="mb-3 text-center heady-gradient">
          Generate your WhatsApp Chat <br /> Button on your website
        </h2>
        <p className="px-5">
          Plug in this free WhatsApp widget and keep the conversation going with your customers even if they leave your website.
          <br /> Generate your own website WhatsApp button in 3 simple steps.
        </p>
      </div>

      <div className="row mb-5">
        {/* Form Column */}
        <div className="col-lg-6 col-md-6 col-sm-12 pe-md-5 text-start widget-form">
          <form onSubmit={handleSubmit} className="widget-form">
            <h6 className="pb-3 fw-bold">Customize Chat Button Settings</h6>

            <label>
              Button Name (optional)
              <input
                type="text"
                name="buttonName"
                value={formData.buttonName}
                maxLength={18}
                onChange={handleInputChange}
              />
            </label>

            <div className="mb-3">
              <span>Button Size:</span>
              <div className="d-flex align-items-center gap-3 mt-2">
                {["Small", "Medium", "Large"].map((size) => (
                  <label key={size} className="form-check-label d-flex align-items-center">
                    <input
                      type="radio"
                      name="buttonSize"
                      value={size}
                      checked={formData.buttonSize === size}
                      onChange={handleInputChange}
                      className="form-check-input me-2"
                    />
                    {size}
                  </label>
                ))}
              </div>
            </div>

            <div>
              Button Position:
              <div className="d-flex align-items-center gap-3 mt-2">
                {["Left", "Right"].map((position) => (
                  <label key={position} className="form-check-label d-flex align-items-center">
                    <input
                      type="radio"
                      name="buttonPosition"
                      value={position}
                      checked={formData.buttonPosition === position}
                      onChange={handleInputChange}
                      className="form-check-input me-2"
                    />
                    {position}
                  </label>
                ))}
              </div>
            </div>

            <h6 className="py-3">Customize Widget Settings</h6>

            <label>
              Brand Name:
              <input
                type="text"
                name="brandName"
                value={formData.brandName}
                maxLength={35}
                onChange={handleInputChange}
              />
            </label>

            <label>
              Brand Subtitle:
              <input
                type="text"
                name="brandSubtitle"
                value={formData.brandSubtitle}
                maxLength={50}
                onChange={handleInputChange}
              />
            </label>

            <label>
              Phone Number:
              <input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
              />
            </label>

            <label>
              Brand Image URL:
              <input
                type="text"
                name="brandImageUrl"
                value={formData.brandImageUrl}
                onChange={handleInputChange}
              />
            </label>

            <label>
  Welcome Message:
  <textarea
    name="welcomeMessage"
    value={formData.welcomeMessage.replace(/<br>/g, '\n')} // Replace <br> with \n for textarea
    maxLength={700}
    onChange={handleInputChange}
  />
</label>


            <label>
              Pre-filled Message:
              <textarea
                name="preFilledMessage"
                value={formData.preFilledMessage}
                maxLength={200}
                onChange={handleInputChange}
              />
            </label>

            <label>
              Call to Action:
              <input
                type="text"
                name="callToAction"
                value={formData.callToAction}
                maxLength={12}
                onChange={handleInputChange}
              />
            </label>

            <label className="form-check d-flex align-items-center">
              <input
                type="checkbox"
                name="agreeToMarketing"
                checked={formData.agreeToMarketing}
                onChange={handleInputChange}
                className="form-check-input"
              />
              <span className="form-check-label small ms-3 text-capitalize mt-3">
                I agree to receive marketing communications and other <br />
                promotional offers from Anantya.ai.
              </span>
            </label>

            <button type="submit" className="btn skip_button_no mt-2 py-2">Get Your Free WhatsApp Button</button>
          </form>
        </div>

        {/* Preview Column */}
        <div className="col-lg-6 col-md-6 col-sm-12 px-md-5 px-4 mb-5">
          <div id="whatsapp-previews" className="whatsapp-previews">
            <p className="text-start ms-2 fs-small fw-bold">Button Preview</p>

            <div className="iphone-layout" style={{ backgroundImage: `url(${whatsappBackgroundImg})` }}>
              <div id="profile-info" className="profile-info">
                <div className="profile-pic">
                  <img
                    src={formData.brandImageUrl || seekImage}
                    alt="User's profile"
                  />
                </div>
                <div className="mobile-number me-auto">
                  <span id="mobileLabel" className="mobile-label">{formData.brandName}</span>
                  <p className="user-online">{formData.brandSubtitle}</p>
                </div>
              </div>

              <div className="phone-screen">
                <div className="phone-content">
                  <div id="chat-preview" className="chat-previews">
                  <p
  className="welcome-message"
  dangerouslySetInnerHTML={{ __html: formData.welcomeMessage }}
></p>

                <p className="pre-filled-message">{formData.preFilledMessage}</p>

                  </div>
                </div>
              </div>

              <div className="cta-section">
                <button className="cta-button mt-3"> <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" width="22" height="22"><path d="m.76 21.24 1.412-5.12A10.324 10.324 0 0 1 .76 10.93C.76 5.35 5.35.76 10.964.76 16.58.76 21.24 5.35 21.24 10.93c0 5.578-4.661 10.31-10.276 10.31-1.765 0-3.46-.565-4.978-1.413L.76 21.24Z" fill="#EDEDED"></path><path d="m6.268 17.991.318.177c1.307.812 2.825 1.306 4.414 1.306 4.626 0 8.474-3.848 8.474-8.545 0-4.696-3.848-8.404-8.51-8.404-4.66 0-8.439 3.743-8.439 8.404 0 1.624.46 3.213 1.307 4.555l.212.318-.812 2.966 3.036-.777Z" fill="#25D366"></path><path d="m8.245 6.198-.671-.036a.802.802 0 0 0-.565.212c-.318.283-.848.812-.989 1.519-.247 1.059.141 2.33 1.06 3.601.918 1.271 2.683 3.32 5.79 4.202.989.283 1.766.106 2.402-.282.494-.318.812-.812.918-1.342l.105-.494a.355.355 0 0 0-.176-.389l-2.225-1.024a.337.337 0 0 0-.423.106l-.883 1.13a.275.275 0 0 1-.283.07c-.6-.211-2.613-1.059-3.707-3.177-.036-.106-.036-.212.035-.283l.848-.953c.07-.106.105-.247.07-.353L8.527 6.41a.308.308 0 0 0-.282-.212Z" fill="#FEFEFE"></path></svg> {formData.callToAction}</button>
                <p className="powered-by mt-2"> <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.84653 0.0360377C6.13974 0.138097 6.33912 0.438354 6.33912 0.777828V4.66669L9.15651 4.66669C9.41915 4.66669 9.65997 4.82804 9.78125 5.08527C9.90254 5.34251 9.88415 5.65289 9.73354 5.89048L4.80311 13.6682C4.62681 13.9463 4.30753 14.066 4.01433 13.964C3.72113 13.8619 3.52174 13.5616 3.52174 13.2222L3.52174 9.33331H0.704349C0.441715 9.33331 0.200895 9.17196 0.0796083 8.91473C-0.0416787 8.65749 -0.0232851 8.34711 0.127325 8.10952L5.05775 0.331805C5.23405 0.0536972 5.55333 -0.0660216 5.84653 0.0360377Z" fill="#FFA800"></path></svg> &nbsp;
                  Powered by <a href="/" target="_blank" rel="noopener noreferrer" className="powered-by-link">Anantya.ai</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Script Box */}
      {showScript && (
        <div className="mt-5">
          <h4>Copy this code and paste before the <code>&lt;/body&gt;</code> tag on every page of your website:</h4>
          <div className="script-box">
            <pre>{generatedScript}</pre>
            <button className="btn btn-primary mt-2" onClick={copyToClipboard}>Copy to Clipboard</button>
          </div>
        </div>
      )}
    </section>
  );
};

export default WhatsAppWidget;
