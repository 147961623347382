import axios from "axios";

export const getCountryByIP = async () => {
  try {
    const response = await axios.get("https://ipinfo.io?token=0ea00db912f168");
    return response.data.country;
  } catch (error) {
    console.error("Error fetching IP information:", error);
    return null; // Default to null if there's an error
  }
};  
