import React, { useState, useEffect } from "react";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import FacebookLogin from "react-facebook-login";
import { getImage } from "../../../utils/getImage"; // Adjusted path
import { jwtDecode } from "jwt-decode";
import { apiBaseurl } from "../../../utils/constant";
const loginImg = getImage("login-page.png");

const GetStartedForm = ({ handleGoogleSignIn, handleFacebookSignIn, handleFormSubmit, onNext }) => {
  const [error, setError] = useState(""); // State to hold error messages
  const [isChecked, setIsChecked] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    company: "",
  });
  const [otp, setOtp] = useState(""); // To store the entered OTP
  const [generatedOtp, setGeneratedOtp] = useState(null); // Store the OTP sent to the user
  const [otpFieldVisible, setOtpFieldVisible] = useState(false); // Control OTP field visibility

  useEffect(() => {
    // Load user data from localStorage if available
    const savedData = JSON.parse(localStorage.getItem("userData"));
    if (savedData) {
      setFormData(savedData);
      // sendOtp(savedData.email , savedData.name)
    }
  }, []);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const sendOtp = async (email, name) => {
    if (!email || !name) {
      return;
    }
    // Validate email format before making the API call
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }


    try {
      // const response = await fetch("http://localhost:5000/api/send-otp", {
        const response = await fetch(`${apiBaseurl}send-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, name }), // Include name along with email
      });

      if (response.ok) {
        const data = await response.json();
        setGeneratedOtp(data.otp); // Store the OTP sent by the server
        setOtpFieldVisible(true); // Show the OTP field
        alert("OTP sent to your email!");
      } else {
        alert("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      alert("Error sending OTP. Please check your email and try again.");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Automatically send OTP when email changes
    // if (name === "email" && value) {
    //   sendOtp(value, formData.name);  // Send OTP with email and name
    // }
  };

  const verifyOtp = () => {
    if (otp === generatedOtp) {
      alert("OTP verified successfully!");
    } else {
      alert("Invalid OTP. Please try again.");
    }
  };

  const handleSubmit = async () => {
    const { name, email, mobile, company } = formData;

    if (!otp || otp !== generatedOtp) {
      alert("Please verify your OTP before proceeding.");
      return;
    }

    if (name && email && mobile && company) {
      try {
        // const response = await fetch("http://localhost:5000/api/send-email", {
          const response = await fetch(`${apiBaseurl}send-email`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            mobile,
            company,
          }),
        });

        if (response.ok) {
          const data = await response.json(); // Parse the JSON response
          setShowAlert(true); // Show success alert
          handleFormSubmit(formData);
          if (onNext) onNext(); // Move to the next step
        } else {
          const errorData = await response.json(); // Parse error response
          throw new Error(errorData.message || "Failed to send email");
        }
      } catch (error) {
        console.error("Error:", error);
        alert(`Error: ${error.message || "Failed to send email"}`);
      }
    } else {
      alert("Please fill all the fields.");
    }
  };

  console.log(process.env.dev)

  const handleGoogleSuccess = (credentialResponse) => {
    if (handleGoogleSignIn) handleGoogleSignIn(credentialResponse);

    const decode = jwtDecode(credentialResponse?.credential);
    const userName = decode?.name;
    const userEmail = decode?.email;

    setFormData({
      name: userName || "",
      email: userEmail || "",
      mobile: "",
      company: "",
    });
    // Store Google login data in localStorage
    localStorage.setItem("userData", JSON.stringify({
      name: userName || "",
      email: userEmail || "",
      mobile: "",
      company: "",
    }));
    console.log("User Details from Google:", userName, userEmail); // Log to console
    if (onNext) onNext();
  };

  const handleFacebookSuccess = (response) => {
    if (handleFacebookSignIn) handleFacebookSignIn(response);

    const userName = response?.name;
    const userEmail = response?.email;

    setFormData({
      name: userName || "",
      email: userEmail || "",
      mobile: "",
      company: "",
    });
    // Store Facebook login data in localStorage
    localStorage.setItem("userData", JSON.stringify({
      name: userName || "",
      email: userEmail || "",
      mobile: "",
      company: "",
    }));
    console.log("User Details from Facebook:", userName, userEmail); // Log to console
    if (onNext) onNext();
  };

  return (
    <GoogleOAuthProvider clientId="618682726438-m3jvrirqfd5ps228qlnfo7u6buhnf9lu.apps.googleusercontent.com">
      <>
        {showAlert && (
          <div className="alert alert-success top-right-alert">
            Form submitted and email sent successfully!
          </div>
        )}



        <div className="left-image my-auto d-none d-md-block ps-4  py-3 mt-3 my-auto">
          <img
            src={loginImg}
            className="img-fluid"
            alt="login-img"
            style={{
              width: "430px",
              height: "auto",
              borderRadius: "30px",

            }}
          />
        </div>

        <div className="right-form px-5 mx-md-3 py-3 mt-3">
          <h5 className="mb-3">Get Started With Anantya.ai</h5>
          {/* <small className="mt-0 mb-3">Getting started is easy</small> */}

          <div className="text-center d-flex justify-content-center">
            {/* Google Login */}
            <GoogleLogin onSuccess={handleGoogleSuccess} onError={(error) => console.log("Google Login Error:", error)} />
            {/* Facebook Login */}
            <FacebookLogin
              appId="3905033793156836"
              fields="name,email,picture"
              callback={handleFacebookSuccess}
              cssClass="google-btn ms-3"
              icon="fa-facebook me-2"
              textButton="Facebook"
            />
          </div>

          <div className="divider-container">
            <div className="divider-line"></div>
            <div className="divider-text">Or continue with</div>
            <div className="divider-line"></div>
          </div>

          <form className="px-md-4"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <div className="form-group">
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="Enter name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className=" otp_Section">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              <button
                type="button"
                className="btn rounded-pill login_button otp_button"
                onClick={() => sendOtp(formData.email, formData.email)}
              >
                Send OTP
              </button>
              {error && <small style={{ color: 'red' }}>{error}</small>}
            </div>

            {otpFieldVisible && (
              <div className="otp_Section">
                <input
                  type="text"
                  name="otp"
                  className="form-control"
                  placeholder="Enter Email OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
                <button
                  type="button"
                  className="btn rounded-pill login_button otp_button"
                  onClick={verifyOtp}
                >
                  Verify OTP
                </button>
              </div>
            )}

            <div className="form-group">
              <input
                type="tel"
                name="mobile"
                className="form-control"
                placeholder="WhatsApp number"
                value={formData.mobile}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                name="company"
                className="form-control"
                placeholder="Company name"
                value={formData.company}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="ps-2">
              <div
                className="mt-1 mb-2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "11px",
                  justifyContent: "flex-start",
                  float: "left",
                }}
              >
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  style={{ marginRight: "10px", width: "25px" }}
                  required
                />
                Accept terms and conditions
              </div>
            </div>
            <button
              type="submit"
              disabled={!isChecked}
              className={`btn-submit ${!isChecked ? "disabled" : ""}`}
            >
              Get Started
            </button>

          </form>
        </div>

      </>
    </GoogleOAuthProvider>
  );
};

export default GetStartedForm;
