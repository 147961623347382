import React from "react";
import "./ImageGrid.css"; // Add appropriate styling here
import { getImage } from '../../utils/getImage'; // Utility function for dynamic image fetching

// Define an array of image names
const images = [
  "/whatsapp-pricing/integration/Group 9272.png", "/whatsapp-pricing/integration/Group 9275.png", "/whatsapp-pricing/integration/Group 9277.png", "/whatsapp-pricing/integration/Group 9278.png",
  "/whatsapp-pricing/integration/Group 9281.png", "/whatsapp-pricing/integration/Group 9283.png", "/whatsapp-pricing/integration/Group 9285.png", "/whatsapp-pricing/integration/Group 9286.png",
  "/whatsapp-pricing/integration/Group 9273.png", "/whatsapp-pricing/integration/Group 9274.png", "/whatsapp-pricing/integration/Group 9276.png", "/whatsapp-pricing/integration/Group 9279.png",
  "/whatsapp-pricing/integration/Group 9280.png", "/whatsapp-pricing/integration/Group 9282.png", "/whatsapp-pricing/integration/Group 9284.png", "/whatsapp-pricing/integration/Group 9287.png",
];

const ImageGrid = () => {
  return (
    <div className="image-grid-section ">
      <div className="container py-2">
        <h2 className="bg-gradient_headings mb-3">
          Seamless integration with 15+ popular business platforms
        </h2>

        <div className="image-grid mb-3">
          {images.map((image, index) => (
            <div className="image-item" key={index}>
              <img
                src={getImage(image)} // Fetch image dynamically using getImage
                alt={`Logo of ${image.split('/').pop().replace('.png', '')}`} // Descriptive alt text
                className="img-fluid rounded"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageGrid;
