import React, { useState } from "react";
import './FAQSection.css'; // Ensure you have the CSS file in the same directory

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    { question: "How can I create a WhatsApp link for my business?", answer: "Enter your phone number, customize the message, and generate the link to share with customers." },
    { question: "Is the WhatsApp link compatible with all devices?", answer: "Yes, the link works on both mobile and desktop devices" },
    { question: "Can I include a pre-filled message", answer: "Yes, you can set a default message that will appear when someone clicks on the link." },
    { question: "Can I track the clicks on the WhatsApp link?", answer: "You can integrate tracking tools to measure engagement." },
    { question: "Is the service free to use?", answer: " Yes, generating a WhatsApp link is completely free." },
  ];

  return (
    <div className="faq-unique-section container">
      <h2 className="text-center mb-5  heading-gradient">Frequently Asked Questions</h2>
      <div className="row">
        {/* Column 1 */}
        <div className="col-md-6">
          {faqs.slice(0, 2).map((faq, index) => (
            <div
              key={index}
              className={`faq-item ${activeIndex === index ? "active" : ""}`}
            >
              <div
                className="faq-question d-flex justify-content-between align-items-center"
                onClick={() => toggleFAQ(index)}
              >
                <p>{faq.question}</p>
              
                <span className="faq-toggle-btn">{activeIndex === index ? "-" : "+"}</span>
              </div>
              {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
            </div>
          ))}
        </div>
        {/* Column 2 */}
        <div className="col-md-6">
          {faqs.slice(2).map((faq, index) => (
            <div
              key={index + 2}
              className={`faq-item ${activeIndex === index + 2 ? "active" : ""}`}
            >
              <div
                className="faq-question d-flex justify-content-between align-items-center"
                onClick={() => toggleFAQ(index + 2)}
              >
                <p>{faq.question}</p>
            
                <span className="faq-toggle-btn">{activeIndex === index + 2 ? "-" : "+"}</span>
              </div>
              {activeIndex === index + 2 && <p className="faq-answer">{faq.answer}</p>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;

