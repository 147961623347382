import React, { useState } from "react";
import "./Reasons.css"; // CSS
import { getImage } from "../../utils/getImage";

// Get images dynamically
const image1 = getImage("/whatsapp-pricing/sack-dollar.png");
const image2 = getImage("/whatsapp-pricing/messages.png");
const image3 = getImage("/whatsapp-pricing/membership.png");
const image4 = getImage("/whatsapp-pricing/cart-arrow-down.png");
const image5 = getImage("/whatsapp-pricing/customer-care.png");
const image6 = getImage("/whatsapp-pricing/lead-funnel.png");
const auth1 = getImage("/whatsapp-pricing/Group 9253.png");
const auth2 = getImage("/whatsapp-pricing/Group 9254.png");
const auth3 = getImage("/whatsapp-pricing/Group 9255.png");
const auth4 = getImage("/whatsapp-pricing/Group 9256.png");
const auth5 = getImage("/whatsapp-pricing/Group 9257.png");
const auth6 = getImage("/whatsapp-pricing/Group 9258.png");
const auth7 = getImage("/whatsapp-pricing/Group 9259.png");
const auth8 = getImage("/whatsapp-pricing/Group 9260.png");
const auth9 = getImage("/whatsapp-pricing/Group 9261.png");
const auth10 = getImage("/whatsapp-pricing/Group 9262.png");
const auth11 = getImage("/whatsapp-pricing/Group 9263.png");
const auth12 = getImage("/whatsapp-pricing/Group 9264.png");
const auth13 = getImage("/whatsapp-pricing/Group 9265.png");
const close = getImage("/whatsapp-pricing/close.svg");
const tick = getImage("/whatsapp-pricing/tick.svg");
const sign = getImage("/whatsapp-pricing/quote1.svg");
const chat = getImage("/whatsapp-pricing/Chat.png");
const contract = getImage("/whatsapp-pricing/Contract.png");

const WhyHiverSection = () => {
  const [currentContentIndex, setCurrentContentIndex] = useState(0); // State to manage content
  const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handlePopupToggle = () => setIsPopupOpen(!isPopupOpen);
  const handleNestedPopupToggle = () => setIsNestedPopupOpen(!isNestedPopupOpen);
  const [isNestedPopupOpen, setIsNestedPopupOpen] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
  
    // Gather form data
    const formData = {
      name: document.getElementById("name").value,
      email: document.getElementById("email").value,
      whatsapp: document.getElementById("whatsapp").value,
      company: document.getElementById("company").value,
    };
  
    // Send the form data as JSON
    fetch("https://formspree.io/f/mdorezev", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          alert("Form submitted successfully!");
          document.getElementById("contactForm").reset();
        } else {
          alert("Failed to submit the form.");
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        alert("An error occurred while submitting the form.");
      });
  };
  

  const contentData = [
    {
      title: "Customer feedback",
      text: "The ease of integration and customization options made Anantya.ai the ideal solution for our company. We’ve seen tangible results in just a few months.",
      author: "Elegance Style Perfumes And Gifts",
      position: "Product Manager",
      authorImage: auth1, // Correct assignment
    },
    {
      title: "Customer feedback",
      text: "Anantya.ai has transformed our communication strategy! The WhatsApp API integration was seamless, and our customer engagement has skyrocketed",
      author: "Advik Media Advertising L.L.C",
      position: "Marketing Head",
      authorImage: auth2, // Correct assignment
    },
    {
      title: "Customer feedback",
      text: "Thanks to Anantya.ai, our response times have drastically improved. We can now connect with customers instantly, offering faster and more personalized support.",
      author: "kinmarche",
      position: "Customer Support Manager",
      authorImage: auth3, // Correct assignment
    },
    {
      title: "Customer feedback",
      text: "Anantya.ai's WhatsApp solution has been a game-changer for our business. Our customer engagement is at an all-time high, and our team is more productive than ever.",
      author: "FINMART FINANCIAL SERVICES LLC",
      position: "CEO",
      authorImage: auth4, // Correct assignment
    },
    {
      title: "Customer feedback",
      text: "The Anantya.ai team is always responsive and ready to help. They’ve taken our messaging game to the next level with innovative solutions and support.",
      author: "Micris Dental Clinic LLC",
      position: "Senior Business Analyst",
      authorImage: auth5, // Correct assignment
    },
    {
      title: "Customer feedback",
      text: "Anantya.ai makes it incredibly easy to manage customer interactions across multiple channels. Their dashboard is intuitive and simple to use",
      author: "SYMBIOSIS INTERNATIONAL UNIVERSITY",
      position: "Customer Success Lead",
      authorImage: auth6, // Correct assignment
    },
    {
      title: "Customer feedback",
      text: "We’ve been able to engage with our clients in real time thanks to Anantya.ai’s easy-to-use platform. The results speak for themselves",
      author: "Al Arrayed Eye Specialized Center W.L.L",
      position: "Marketing Director",
      authorImage: auth7, // Correct assignment
    },
    {
      title: "Customer feedback",
      text: "The support from Anantya.ai has been incredible. We can count on them to resolve issues quickly, and their tools keep us ahead of the competition.",
      author: "ASGHAR ALI CO.W.L.L",
      position: "CTO",
      authorImage: auth8, // Correct assignment
    },
    {
      title: "Customer feedback",
      text: "From lead management to automated responses, Anantya.ai has streamlined our entire communication process. It's been an incredible experience for our team and clients",
      author: "Global Travel & Tourism W.L.L",
      position: "Operations Manager",
      authorImage: auth9, // Correct assignment
    },
    {
      title: "Customer feedback",
      text: "The level of customization Anantya.ai offers is unmatched. Whether it's tweaking our WhatsApp messages or setting up integrations, they’ve helped us tailor everything to our needs.",
      author: "KHALAIFAT CO. W.L.L",
      position: "Marketing Coordinator.",
      authorImage: auth10, // Correct assignment
    },
    {
      title: "Customer feedback",
      text: "The customer support at Anantya.ai is exceptional! They’re always there to assist with any questions or challenges. Their attention to detail and willingness to help is what sets them apart..",
      author: "Muxtech",
      position: "Business Development Manager",
      authorImage: auth11, // Correct assignment
    },
    {
      title: "Customer feedback",
      text: "Anantya.ai’s customer support team has been amazing. They helped us customize our system and ensured a smooth implementation process. We couldn’t be happier.",
      author: "Al Marwaan Real Estate Development LLC (AI)",
      position: "Operations Director",
      authorImage: auth12, // Correct assignment
    },
    {
      title: "Customer feedback",
      text: "Anantya.ai’s dedication to providing the right solution is evident in their customer service. They’re always quick to offer support and assist with any customizations we need.",
      author: "NATURAL HEALING MEDICAL CENTER L L C",
      position: "Head of Customer Service",
      authorImage: auth13, // Correct assignment
    },
    // Add more content objects here for content 2-13
  ];

  const handleNextContent = () => {
    setCurrentContentIndex((prevIndex) => (prevIndex + 1) % contentData.length); // Switch content
  };

  return (
    <section className="row pb-lg-0 pb-md-0 pb-5">
      <section className="main-wrapper col-lg-6 col-md-6 col-sm-12">
        <h1 className="top-section heading ">
          <span>13 reasons why </span> Anantya.ai <br /> will win you over!
        </h1>
        <section className="content-main">
          <div className="contents ps-md-5 ps-0">
            <div
              className={`content-div-1 content-div mt-5 ${currentContentIndex === 0 ? "" : "fade-out"}`}
            >
              <div className="content-inner">
                <div className="content-text-wrapper p-4">
                  <div className="advantage subheading">
                    {contentData[currentContentIndex].title}
                  </div>
                  <div className="content-text text">
                    {contentData[currentContentIndex].text}
                  </div>
                </div>
                <div className="author-details-outer p-3  ">
                  <div className="author-img">
                    <img
                      src={contentData[currentContentIndex].authorImage}
                      alt="Author"
                    />
                  </div>
                  <div className="author-details-inner d-flex align-items-center justify-content-between w-100">
                    <div>
                      <div className="author-name text">{contentData[currentContentIndex].author}</div>
                      <div className="author-pos text">{contentData[currentContentIndex].position}</div>
                    </div>
                    <div className="quotes-img">
                      <img
                        src={sign}
                        alt="quotes"
                        className="float-end"
                      />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <section className="choice">
            <div className="choice_inner">
              <div className="choice_no choice_btn">
                <div className="choice_no_inner mt-3">
                  <button onClick={handleNextContent}
                    className="reason_btn me-3">
                    <img
                      src={close}
                      alt="Close"
                    />
                    <span> Give me another reason </span>
                  </button>
                  <button onClick={handlePopupToggle} className="reason_btn_2 mt-md-0 mt-3">
                    <img
                      src={tick}
                      alt="tick"
                    />
                    <span> Looks interesting </span>
                  </button>
                </div>
              </div>
            </div>
          </section>
        </section>
      </section>
      <section className=" col-lg-6 col-md-6 col-sm-12 px-md-5">
        <h1 className="px-md-0 px-4 top-section heading mb-1 mt-4">The Numbers Tell <span> Our Story</span></h1>

        <p className="px-5 mx-4 mt-0">Discover how Anantya.ai is transforming customer engagement with smart, easy-to-use solutions.</p>
        <div className="row row-cols-1 row-cols-md-2 gx-4 gy-2  px-md-4 px-4">
          <div className="col">
            <div className="stroy-card stroy-card_1 h-100">
              <div className="icon">
                <img src={image1} className="img-fluid" alt="icon" />
              </div>
              <div className="info">
                <h5 className="card-title">70%</h5>
                <p className="text">
                  Lower Cost per Lead
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="stroy-card stroy-card_2  h-100">
              <div className="icon">
                <img src={image2} className="img-fluid" alt="icon" />
              </div>
              <div className="info">
                <h5 className="card-title">2X</h5>
                <p className="text">
                  Higher Open Rates
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="stroy-card stroy-card_3  h-100">
              <div className="icon">
                <img src={image3} className="img-fluid" alt="icon" />
              </div>
              <div className="info">
                <h5 className="card-title">58%</h5>
                <p className="text">
                  Increase in Customer Loyalty
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="stroy-card stroy-card_4 h-100">
              <div className="icon">
                <img src={image4} className="img-fluid" alt="icon" />
              </div>
              <div className="info">
                <h5 className="card-title">60%</h5>
                <p className="text">
                  Abandoned Cart Recovery
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="stroy-card stroy-card_5  h-100">
              <div className="icon">
                <img src={image5} className="img-fluid" alt="icon" />
              </div>
              <div className="info">
                <h5 className="card-title">75%</h5>
                <p className="text">
                  Boost in Customer Engagement
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="stroy-card stroy-card_6  h-100">
              <div className="icon">
                <img src={image6} className="img-fluid" alt="icon" />
              </div>
              <div className="info">
                <h5 className="card-title">5X</h5>
                <p className="text">
                  Growth in Lead Generation
                </p>
              </div>
            </div>
          </div>

        </div>
      </section>

      {/* Main Popup */}
      {isPopupOpen && (
        <div className="popup-overlay">

          <div className="popup-content  p-0 rounded-4 w-100"  style={{ maxWidth: "500px" }}>
            <div className="d-flex justify-content-center gradient p-1">
              <h2 className="text-white">We’re glad you like us!</h2>
              <button className="wp-popup-close-btn" onClick={handlePopupToggle}>
                &times;
              </button>
            </div>
            <h5 className="pt-4">We’re glad you like us!</h5>
            <div className=" d-flex justify-content-center ">
              <img src={chat} className="img-fluid chat-icon me-5" alt="chat" />
              <img src={contract} className="img-fluid chat-icon ms-5" alt="contract" />

            </div>
            <div className="popup-actions py-4">
              <a
                href="https://calendly.com/info-w0m/30min?month=2025-01"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn btn-pop me-3">Talk to Specialist</button>
              </a>
              <button className="btn btn-pop" onClick={handleNestedPopupToggle}>
                I’m sold, sign me up!
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Nested Popup */}
      {isNestedPopupOpen && (
        <div className="popup-overlay d-flex justify-content-center text-center align-items-center">
          <div className="popup-content bg-white rounded-5 p-md-5 p-3 w-100" style={{ maxWidth: "500px" }}>
            <div className="d-flex justify-content-between align-items-center text-center ">
              <h5 className="h5 mb-3 mx-auto">Sign Up</h5>
              <button
                className="btn-close"
                onClick={handleNestedPopupToggle}
                aria-label="Close"
              ></button>
            </div>
            <form id="contactForm" onSubmit={handleSubmit}>
  <div className="form-group mb-3 text-center">
    <input
      type="text"
      id="name"
      className="form-control"
      placeholder="Enter your name"
      required
    />
  </div>
  <div className="form-group mb-3">
    <input
      type="email"
      id="email"
      className="form-control"
      placeholder="Enter your email"
      required
    />
  </div>
  <div className="form-group mb-3">
    <input
      type="text"
      id="whatsapp"
      className="form-control"
      placeholder="Enter your WhatsApp number"
      required
    />
  </div>
  <div className="form-group mb-4">
    <input
      type="text"
      id="company"
      className="form-control"
      placeholder="Enter your company name"
      required
    />
  </div>
  <div className="form-actions text-center">
    <button type="submit" className="btn btn-primary w-75">
      Submit
    </button>
  </div>
</form>

          </div>
        </div>
      )}

    </section>
  );
};

export default WhyHiverSection;
