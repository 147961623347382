import React, { useState, useEffect } from "react";
import { QRCodeCanvas } from "qrcode.react";
import "./QRCodeGenerator.css";

function QRCodeGenerator() {
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [frameStyle, setFrameStyle] = useState("none");
  const [shape, setShape] = useState("square");
  const [logo, setLogo] = useState("");
  const [level, setLevel] = useState("L");
  const [qrValue, setQrValue] = useState("");

  const [isCustomized, setIsCustomized] = useState(false); // Track customization

  // Automatically generate QR Code value
  useEffect(() => {
    if (whatsappNumber) {
      const url = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
        welcomeMessage || ""
      )}`;
      setQrValue(url);
    } else {
      setQrValue(""); // Clear QR if no number
    }
  }, [whatsappNumber, welcomeMessage]);

  // Download QR Code as an image
  const downloadQRCode = () => {
    const canvas = document.getElementById("qr-code");
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      const link = document.createElement("a");
      link.href = pngUrl;
      link.download = "qr-code.png";
      link.click();
    } else {
      alert("QR Code is not available for download.");
    }
  };

  return (
    <section>
      <div className="container qr-code mt-5">

        <div className="row qr-code-inner">
          {/* Left Section */}
          <div className="col-lg-9 col-md-9 col-sm-12">
            <h6 className="text-start my-4">1. Complete the Content</h6>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <div style={{ flex: 1 }}>
                <label>Type your WhatsApp Number:</label>
                <input
                  type="text"
                  className="form-control"
                  value={whatsappNumber}
                  onChange={(e) => setWhatsappNumber(e.target.value)}
                  placeholder="+91 Your WhatsApp number here"
                />
              </div>
              <div style={{ flex: 1 }}>
                <label>Welcome Message (Optional)</label>
                <input
                  type="text"
                  className="form-control"
                  value={welcomeMessage}
                  onChange={(e) => setWelcomeMessage(e.target.value)}
                  placeholder="e.g., Hello, I want to know more about your service."
                />
              </div>
            </div>

            <h6 className="text-start my-4 ">2. Design Your QR</h6>
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginBottom: "20px",
                alignItems: "center",
              }}
            >
              <div>
                <label>Frame</label>
                <select
                  className="form-control"
                  value={frameStyle}
                  onChange={(e) => {
                    setFrameStyle(e.target.value);
                    setIsCustomized(true);
                  }}
                >
                  <option value="none">None</option>
                  <option value="rounded">Rounded</option>
                  <option value="circle">Circle</option>
                </select>
              </div>
              <div>
                <label>Shape</label>
                <select
                  className="form-control"
                  value={shape}
                  onChange={(e) => {
                    setShape(e.target.value);
                    setIsCustomized(true);
                  }}
                >
                  <option value="square">Square</option>
                  <option value="circle">Circle</option>
                </select>
              </div>
              <div>
                <label>Logo</label>
                <input
                  type="file"
                  className="form-control pt-3"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      const reader = new FileReader();
                      reader.onload = () => setLogo(reader.result);
                      reader.readAsDataURL(file);
                    }
                    setIsCustomized(true);
                  }}
                />
              </div>
              <div>
                <label> Level</label>
                <select
                  className="form-control"
                  value={level}
                  onChange={(e) => {
                    setLevel(e.target.value);
                    setIsCustomized(true);
                  }}
                >
                  <option value="L">Low</option>
                  <option value="M">Medium</option>
                  <option value="Q">Quartile</option>
                  <option value="H">High</option>
                </select>
              </div>
            </div>
          </div>
          {/* Right Section */}
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="down-qr-code p-3">
              <h6>3. Download Your QR</h6>
              <div>
                <QRCodeCanvas
                  id="qr-code"
                  value={qrValue || "https://wa.me/"}
                  size={200}
                  bgColor="#ffffff"
                  fgColor="#000000"
                  level={level}
                  style={{
                    border:
                      frameStyle === "rounded"
                        ? "10px solid #0A9F8D"
                        : frameStyle === "circle"
                        ? "10px solid #0A9F8D"
                        : "none",
                    borderRadius: shape === "circle" ? "50%" : "0",
                  }}
                  includeMargin
                  imageSettings={
                    logo
                      ? {
                          src: logo,
                          x: undefined,
                          y: undefined,
                          height: 40,
                          width: 40,
                          excavate: true,
                        }
                      : undefined
                  }
                />
              </div>
              <button
                className="qr-btn"
                onClick={downloadQRCode}
                disabled={!isCustomized}
                style={{
                  padding: "10px 20px",
                  backgroundColor: isCustomized ? "#0A9F8D" : "#ccc",
                  color: "#fff",
                  border: "none",
                  cursor: isCustomized ? "pointer" : "not-allowed",
                  marginTop: "20px",
                  
                }}
              >
                Download QR
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default QRCodeGenerator;
